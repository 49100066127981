
.App{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: calc(var(--vh, 1vh) * 100);
  width:100%;
  color: #ffffff;
  font-size:24px;
  text-align: center;
  box-sizing: border-box;
  overflow-x:hidden;
}

.header{
  min-height:100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@keyframes animate {
  0%{
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
  }
  100%{
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
  }
}

.background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background: #000000;
  overflow: hidden;
  z-index:-1;
}
.background li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 15s linear infinite;
}




.background li:nth-child(0) {
  left: 5%;
  width: 375px;
  height: 375px;
  bottom: -375px;
  animation-delay: 1s;
}
.background li:nth-child(1) {
  left: 61%;
  width: 412px;
  height: 412px;
  bottom: -412px;
  animation-delay: 2s;
}
.background li:nth-child(2) {
  left: 64%;
  width: 216px;
  height: 216px;
  bottom: -216px;
  animation-delay: 4s;
}
.background li:nth-child(3) {
  left: 21%;
  width: 330px;
  height: 330px;
  bottom: -330px;
  animation-delay: 11s;
}
.background li:nth-child(4) {
  left: 28%;
  width: 336px;
  height: 336px;
  bottom: -336px;
  animation-delay: 1s;
}

.techStack{
  background:rgba(255, 255, 255, 0.11);
  width:150px;
  height:150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  transition: 0.2s;
  font-size:14px;
  font-weight:500;
  gap:10px;
  backdrop-filter: blur(10px);
  z-index:1;
}
.techStackImg{
  transition:0.2s;
  border-radius:5px;
}

.techStack:hover{
  background:rgba(0, 98, 255, 0.393);
}

.techStack:hover .techStackImg{
  transform: scale(1.1);
}

.techStackContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  max-width:70vw;
}

.title{
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top:50px;
}

.name{
  font-size: 72px;
  font-weight: 700;
  margin-bottom: 20px;

}

.projects{
  display:flex;
  gap:20px;
  flex-direction: row;
  flex-wrap:wrap;
  max-width:80vw;
}

.project{
  border: 1px solid rgba(255, 255, 255, 0.459);
  border-radius:20px;
  padding:25px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width:27%;
  backdrop-filter: blur(10px);
  transition: 0.2s;
}

.project:hover{
  background: rgba(0, 140, 255, 0.121);
}

.projectTitle{
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
  display:flex;
  gap:10px;
  align-items: center;
}

.projectDescription{
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: left;
}

.visitProject{
  font-size: 16px;
  background:rgba(255, 255, 255, 0.155);
  padding:10px 20px;
  border-radius: 10px;
  transition: 0.2s;
  cursor:pointer;
  margin-top:auto;
  color:white;
  text-decoration: none;
}

.visitProject:hover{
  background:rgba(0, 140, 255, 0.393);
}

.menu{
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
  gap: 50px;
  padding:20px;
  font-size:16px;
  background:rgba(0, 0, 0, 0.651);
  backdrop-filter: blur(10px);
  position:fixed;
  top:0;
  z-index:10000;
}

.menuitem{
  cursor:pointer;
  transition: 0.2s;
}

.menuitem:hover{
  color: #00aeff;
}

.fixedScrollButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  background: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size:16px;
  z-index: 100;
}

.fixedScrollButton:hover {
  background: #333;
}

.weOffer{
  max-width: 50%;
}

.teamContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 100px;
  max-width:80vw;
}


.teamMember{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 20px;
  padding: 20px;
  border-radius: 20px;
}

.profileImage{
  border-radius: 50%;
  border:5px solid rgba(255, 255, 255, 0.845);
}

.profileRight{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}

.profileNmae{
  font-size: 24px;
  font-weight: 700;
}

.profileDescription{
  font-size: 16px;
  font-weight: 500;
  text-align:left;
}

.textHighlight{
  color: #00aeff;
  font-weight:600;
}

.menuChat{
  background: -webkit-linear-gradient(45deg, #00aeff, #0072ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight:700;
}

.async{
  opacity:0.7
}

.contactsContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 100px;
  margin-top:50px;
}

.contact{
  transition:0.2s;
  cursor:pointer;
}

.contact:hover{
  transform: scale(1.1);
}

.animatedText{
  opacity:0.8;
  font-size:24px;
}

@media screen and (max-width: 768px) {
  .animatedText{
    font-size:16px;
  }
  .header{
    height:auto;
    min-height:100vh;
  }

  .projects{
    max-width:90vw;
  }

  .project{
    width:100%;
  }
  .name{
    font-size: 48px;
  }
  .title{
    font-size: 32px;
  }
  .menu{
    font-size:12px;
    justify-content:space-around;
    width:100%;
    gap:10px;
    padding-left:10px;
    padding-right:10px;
    box-sizing: border-box;
  }

  .weOffer{
    max-width: 90%;
  }
  .techStack{
    width:130px;
    height:130px;
  }
  .techStackContainer{
    max-width:90vw;
    gap:10px;
  }
  .teamContainer{
    gap:30px;
  }
  .contactsContainer{
    gap:50px;
  }
  .teamContainer{
    max-width:90vw;
  }
}

.andMore{
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
  opacity:0.8;
}

.profileDescriptionLong{
  font-size: 16px;
  font-weight: 400;
  text-align:left;
  opacity:0.8;
  max-width:20vw;;
}

@media screen and (max-width: 768px) {
  .profileDescriptionLong{
    max-width:90vw;
  }
  
}